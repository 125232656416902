import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'gestio-cmp-chart',
    templateUrl: './cmp-chart.component.html',
    styleUrls: ['./cmp-chart.component.scss'],
})
export class CmpChartGestioComponent implements OnInit {
    @Input() info: boolean = false;
    @Input() infoLate: boolean = false;

    constructor() {}

    ngOnInit() {}
}
