import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { DxPopoverModule, DxTextBoxModule } from 'devextreme-angular';
import { TextboxLabelGestioComponent } from './textbox-label/textbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxPopoverModule,
        FlexLayoutModule,
    ],
    declarations: [TextboxLabelGestioComponent],
    exports: [TextboxLabelGestioComponent],
})
export class TextboxsComponentsGestioModule {}
