import { AuthAction, AuthActions } from './auth.actions';
import { AuthState, authInitialState } from './auth.state';

export function authReducer(
    state = authInitialState,
    action: AuthAction
): AuthState {
    switch (action.type) {
        case AuthActions.AUTENTICAR_LOGIN: {
            return handleAuthAutenticarLoginAction(state, action);
        }

        case AuthActions.AUTENTICAR_LOGIN_INTERNO: {
            return handleAuthAutenticarLoginInternoAction(state, action);
        }

        case AuthActions.AUTENTICAR_LOGIN_INTERNO_ADMIN: {
            return handleAuthAutenticarLoginInternoAdminAction(state, action);
        }

        case AuthActions.AUTENTICAR_LOGIN_BASE_OTK: {
            return handleAuthAutenticarLoginBaseOTKAction(state, action);
        }

        case AuthActions.AUTENTICAR_LOGIN_BASE_AFIXGRAF: {
            return handleAuthAutenticarLoginBaseAfixGrafAction(state, action);
        }

        case AuthActions.LOGIN_AUTENTICADO: {
            return handleAuthLoginAutenticadoAction(state, action);
        }

        case AuthActions.REFRESH_TOKEN: {
            return handleAuthRefreshTokenAction(state, action);
        }

        case AuthActions.RECUPERAR_LOGIN: {
            return handleAuthRecuperarLoginAction(state, action);
        }

        case AuthActions.LOGIN_RECUPERADO_COM_SUCESSO: {
            return handleAuthLoginRecuperadoAction(state, action);
        }

        case AuthActions.EFETUAR_LOGOUT: {
            return handleAuthEfetuarLogoutAction(state, action);
        }

        case AuthActions.ENVIAR_NOVA_SENHA: {
            return handleAuthEnviarNovaSenhaAction(state, action);
        }

        case AuthActions.ENVIAR_NOVA_SENHA_COM_SUCESSO: {
            return handleAuthEnviarNovaSenhaComSucessoAction(state, action);
        }

        case AuthActions.CRIAR_NOVA_CONTA: {
            return handleAuthCriarNovaContaAction(state, action);
        }

        case AuthActions.CRIAR_NOVA_CONTA_COM_SUCESSO: {
            return handleAuthCriarNovaContaComSucessoAction(state, action);
        }

        case AuthActions.CONFIRMAR_EMAIL_SENHA: {
            return handleAuthConfirmarEmailSenhaAction(state, action);
        }

        case AuthActions.CONFIRMAR_EMAIL_SENHA_COM_SUCESSO: {
            return handleAuthConfirmarEmailSenhaComSucessoAction(state, action);
        }

        case AuthActions.REDEFINIR_SENHA: {
            return handleAuthRedefinirSenhaAction(state, action);
        }
        case AuthActions.REDEFINIR_SENHA_COM_SUCESSO: {
            return handleAuthRedefinirSenhaComSucessoAction(state, action);
        }

        case AuthActions.LIMPAR: {
            return handleAuthLimparAction(state, action);
        }

        case AuthActions.REGISTRAR_LOGIN_COM_SUCESSO:
        case AuthActions.REGISTRAR_LOGIN_WCF_COM_SUCESSO:
        case AuthActions.REGISTRAR_LOGOUT_COM_SUCESSO:
        case AuthActions.ERRO_EXECUTANDO_OPERACAO: {
            return state;
        }

        case AuthActions.ERRO: {
            return handleAuthErroAction(state, action);
        }

        default: {
            return state;
        }
    }
}

function handleAuthAutenticarLoginAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICAR LOGIN ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthAutenticarLoginInternoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICAR LOGIN INTERNO ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthAutenticarLoginInternoAdminAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICAR LOGIN INTERNO ADMIN ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthAutenticarLoginBaseOTKAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICAR LOGIN OTK ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthAutenticarLoginBaseAfixGrafAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("AUTENTICAR LOGIN AFIXGRAF ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthLoginAutenticadoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGIN AUTENTICADO", action.payload)

    return state;
}

function handleAuthRefreshTokenAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("REFRESH TOKEN LOGIN", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthRecuperarLoginAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("RECUPERAR LOGIN", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthLoginRecuperadoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("LOGIN RECUPERADO", action.payload);

    return {
        ...state,
        user: action.payload,
        error: null,
        warning: null,
        isProcessing: false,
    };
}

function handleAuthEfetuarLogoutAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("EFETUAR LOGOUT", action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        isProcessing: false,
    };
}

function handleAuthEnviarNovaSenhaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("ENVIAR NOVA SENHA ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}

function handleAuthEnviarNovaSenhaComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("ENVIAR NOVA SENHA COM SUCESSO ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success:
            'Pronto! As instruções de redefinição de senha foram enviadas para o seu e-mail com sucesso. Caso não localize, não esqueça de verificar na pasta spam.',
        isProcessing: false,
    };
}

function handleAuthCriarNovaContaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("CRIAR NOVA CONTA ->", action.payload)

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}
function handleAuthCriarNovaContaComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log("CRIAR NOVA CONTA COM SUCESSO ->", action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: 'Conta criada com sucesso.',
        isProcessing: false,
    };
}

function handleAuthConfirmarEmailSenhaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log('CONFIRMAR EMAIL/SENHA ->', action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}
function handleAuthConfirmarEmailSenhaComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log('CONFIRMAR EMAIL/SENHA COM SUCESSO ->', action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: 'Confirmação realizada com sucesso.',
        isProcessing: false,
    };
}

function handleAuthRedefinirSenhaAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log('REDEFINIR SENHA ->', action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: true,
    };
}
function handleAuthRedefinirSenhaComSucessoAction(
    state: AuthState,
    action: any
): AuthState {
    // console.log('REDEFINIR SENHA COM SUCESSO ->', action.payload);

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: 'Nova senha redefinida com sucesso.',
        isProcessing: false,
    };
}

function handleAuthLimparAction(state: AuthState, action: any): AuthState {
    // console.log("LIMPAR");

    return {
        ...state,
        user: null,
        error: null,
        warning: null,
        success: null,
        isProcessing: false,
    };
}

function handleAuthErroAction(state: AuthState, action: any): AuthState {
    console.log('AUTH ERROR', action.payload);

    let errorMessage = null;
    let warningMessage = null;

    if (action.payload) {
        errorMessage = action.payload;

        if (action.payload.errorMessage == 'E-mail já cadastrado no sistema.') {
            errorMessage = null;
            warningMessage = {
                titulo: `Atenção.`,
                mensagem: `<div class='mw500'>Seu e-mail já está cadastrado no nosso sistema. Por favor, utilize a opção <b>'Recuperar Senha'</b> na tela de login.<br><br>
                           Qualquer dúvida, por favor, entre em contato com a nossa equipe: <br><br>
                           <a href="mailto:comercial@gestio.com.br">comercial@gestio.com.br</a><br><br>
                           Tel.: (11) 4810-8181<br>&nbsp;</div>`,
            };
        }

        if (action.payload.status == 'invalid_company_blocked') {
            errorMessage = null;
            warningMessage = {
                titulo: ``,
                mensagem: `<div class='mw500'>Olá,<br><br>
                           Seu login está suspenso ou cancelado, por gentileza, entre em contato com a nossa equipe: <br><br>
                           <a href="mailto:comercial@gestio.com.br">comercial@gestio.com.br</a><br><br>
                           Tel.: (11) 4810-8181<br>&nbsp;</div>`,
            };
        }
    } else {
        errorMessage = `Não foi possível realizar o login.`;
        warningMessage = null;
    }

    return {
        ...state,
        error: errorMessage,
        warning: warningMessage,
        isProcessing: false,
    };
}
