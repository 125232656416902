import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';

import { hasRequiredField } from '../../../../app.functions';

@Component({
    selector: 'gestio-textbox-label-phone',
    templateUrl: './textbox-label-phone.component.html',
    styleUrls: ['./textbox-label-phone.component.scss'],
})
export class TextboxLabelPhoneGestioComponent implements OnInit {
    @Input() label: string = 'Telefone';
    @Input() changeLabel: boolean = true;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() mask: string = null;
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() showClearButton: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Telefone inválido.`;
    @Input() maskInvalidMessage: string = `Formato inválido.`;

    @Output() onValidMask = new EventEmitter<any>();

    @ViewChild(DxTextBoxComponent) textBox: DxTextBoxComponent;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (!this.changeLabel) {
            return this.label;
        }

        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                return {
                    message: this.errorInvalid
                        ? this.errorInvalid
                        : `Inválido.`,
                };
            }
        }
    }

    actionValueChanged(e: any) {
        if (e && e.value && this.mask && this.textBox) {
            if (this.textBox.isValid) {
                this.onValidMask.next(e.value);
            } else {
                this.control.setErrors({ invalidMask: true });
            }
        }
    }

    actionKeyUp(e: any) {
        if (this._isSpecialKey(e)) {
            return;
        }

        if (!this.textBox) {
            return;
        }

        let r = this._phoneFormat(this.textBox.text);
        this.textBox.instance.option('value', r);
    }

    private _getOnlyNumbers(e: any) {
        let r = e.replace(/[^0-9]+/g, '');
        return r;
    }

    private _phoneFormat(v): string {
        var r = this._getOnlyNumbers(v);
        if (!r) {
            return r;
        }

        if (r && r.length == 10) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 4) +
                '-' +
                r.substr(6);
            return r;
        }

        if (r && r.length >= 11) {
            r =
                '(' +
                r.substr(0, 2) +
                ') ' +
                r.substr(2, 5) +
                '-' +
                r.substr(7, 4);
            return r;
        }

        if (r && r.length >= 2) {
            r = '(' + r.substr(0, 2) + ') ' + r.substr(2);
            return r;
        }

        return r;
    }

    private _isSpecialKey(e: any): boolean {
        var charCode = e.which ? e.which : e.keyCode;
        return (
            charCode == 8 || // Tab
            charCode == 9 || //
            charCode == 13 || // Enter
            charCode == 16 || // Shift
            charCode == 27 || // Esc
            charCode == 35 || // Esc
            charCode == 36 || // Home
            charCode == 37 || // Esquerda
            charCode == 39 || // Direita
            charCode == 144
        ); // Numlock
    }
}
