import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DevextremeComponentsModule } from '../devextreme-components.module';
import { MaterialComponentsModule } from '../material-components.module';
import { ButtonsComponentsModule } from '../ui-components/components/buttons/buttons-components.module';
import { CmpContentAreaGestioComponent } from './components/areas/content-area/cmp-content-area.component';
import { CmpDetailSectionGestioComponent } from './components/areas/detail-section/cmp-detail-section.component';
import { FirstStepsContentComponent } from './components/areas/first-steps-content/first-steps-content.component';
import { CmpFullPageAreaGestioComponent } from './components/areas/full-page-area/cmp-full-page-area.component';
import { CmpPageAreaGestioComponent } from './components/areas/page-area/cmp-page-area.component';
import { ButtonsComponentsGestioModule } from './components/buttons/buttons-components.module';
import { CheckboxesComponentsGestioModule } from './components/checkbox/checkboxes-components.module';
import { DatagridComponentsGestioModule } from './components/datagrid/datagrid-components.module';
import { FooterComponentsGestioModule } from './components/footer/footer-components.module';
import { RadioGroupComponentsGestioModule } from './components/radio-group/radio-group-components.module';
import { TabsGestioModule } from './components/tabs/tabs.module';
import { TextareasComponentsGestioModule } from './components/textarea/textareas-components.module';
import { TextboxSearchGestioModule } from './components/textbox-search/textbox-search.module';
import { TextboxsComponentsGestioModule } from './components/textbox/textboxs-components.module';
import { CmpTitleAreaGestioComponent } from './components/title-area/cmp-title-area.component';

@NgModule({
    imports: [
        CommonModule,
        DevextremeComponentsModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ButtonsComponentsModule,
    ],
    declarations: [
        FirstStepsContentComponent,
        CmpContentAreaGestioComponent,
        CmpPageAreaGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpTitleAreaGestioComponent,
    ],
    exports: [
        CmpContentAreaGestioComponent,
        FirstStepsContentComponent,
        CmpPageAreaGestioComponent,
        CmpFullPageAreaGestioComponent,
        CmpDetailSectionGestioComponent,
        CmpTitleAreaGestioComponent,
        DatagridComponentsGestioModule,
        ButtonsComponentsGestioModule,
        TextboxsComponentsGestioModule,
        TextareasComponentsGestioModule,
        CheckboxesComponentsGestioModule,
        FooterComponentsGestioModule,
        RadioGroupComponentsGestioModule,
        TabsGestioModule,
        TextboxSearchGestioModule,
    ],
})
export class UIComponentsGestioModule {}
