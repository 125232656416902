import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DxTabsModule } from 'devextreme-angular';
import { TabsComponent } from './tabs.component';
import { TabsDashboardComponent } from './tabs-dashboard.component';

@NgModule({
    imports: [CommonModule, DxTabsModule, FlexLayoutModule],
    declarations: [TabsComponent, TabsDashboardComponent],
    entryComponents: [TabsComponent, TabsDashboardComponent],
    exports: [TabsComponent, TabsDashboardComponent],
})
export class TabsGestioModule {}
