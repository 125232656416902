import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import { DxCheckBoxModule, DxPopoverModule } from 'devextreme-angular';
import { CmpCheckboxLabelGestioComponent } from './checkbox-label/cmp-checkbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatTooltipModule,
        DxCheckBoxModule,
        DxPopoverModule,
    ],
    exports: [CmpCheckboxLabelGestioComponent],
    declarations: [CmpCheckboxLabelGestioComponent],
})
export class CheckboxesComponentsGestioModule {}
