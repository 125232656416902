import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import {
    AuthUser,
    PermissoesCadastros,
    PermissoesCompras,
    PermissoesCrm,
    PermissoesEstoque,
    PermissoesFinanceiro,
    PermissoesSistema,
    PermissoesVendas,
} from '../models/auth-user.model';
import { AuthStoreService } from '../store/auth.store.service';
import {
    AppDictionaryService,
    dictionaryEnum,
} from './../../app-dictionary.service';
import { AppSettings } from './../../app.settings';

@Injectable()
export class AuthService {
    private readonly avisosURL = `${AppSettings.API_ENDPOINT_SISTEMA}/sistema`;

    private _usuario$: Observable<AuthUser>;
    private _usuarioInterno$: Observable<AuthUser>;

    invalidAuth$: Subject<any> = new Subject<any>();

    usuarioAutenticado$: BehaviorSubject<AuthUser> =
        new BehaviorSubject<AuthUser>(null);
    usuarioInterno$: BehaviorSubject<AuthUser> = new BehaviorSubject<AuthUser>(
        null
    );
    loginInterno$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    loginInternoColaboradorNaoDefinido$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    ambienteDemonstracao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    hasBanner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    erro$: Observable<any>;
    aviso$: Observable<any>;
    sucesso$: Observable<any>;

    administrador$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    usuarioOperador$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    tratamentoPerfilClienteNovo$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(true);

    exibirAvisoRequisitanteOperadorAntigo$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    executarFluxoInicialDemonstracao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    executarCarregarBaseModeloDemonstracao$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    autenticado$: Observable<boolean>;
    processando$: Observable<boolean>;

    moduloCadastros$: Observable<boolean>;
    permissoesCadastros$: Observable<PermissoesCadastros>;

    moduloCrm$: Observable<boolean>;
    permissoesCrm$: Observable<PermissoesCrm>;

    moduloVendas$: Observable<boolean>;
    permissoesVendas$: Observable<PermissoesVendas>;

    moduloFinanceiro$: Observable<boolean>;
    permissoesFinanceiro$: Observable<PermissoesFinanceiro>;

    moduloCompras$: Observable<boolean>;
    permissoesCompras$: Observable<PermissoesCompras>;

    moduloEstoque$: Observable<boolean>;
    moduloEstoqueMovimentacoes$: Observable<boolean>;
    permissoesEstoque$: Observable<PermissoesEstoque>;

    moduloSistema$: Observable<boolean>;
    permissoesSistema$: Observable<PermissoesSistema>;

    responsavelCompras$: Observable<boolean>;
    responsavelEstoque$: Observable<boolean>;
    responsavelCrm$: Observable<boolean>;
    responsavelVendas$: Observable<boolean>;
    responsavelFinanceiro$: Observable<boolean>;

    versaoBETA$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    versaoDEV$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    especificoOTK$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especifico903$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoAfixGraf$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    especificoAocep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoBradesco$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoCuiabaMadeiras$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especifico5A$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoMindset$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoMGM$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoBasile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoColymar$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoG2C$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoLeanInstitute$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoTreviso$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoMaterhouse$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoWorkPlanning$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoFontesul$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoGrupoUrsini$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoMervil$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoGENEROS$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoSERVCRED$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoRX$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoNovaVida$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoWEK$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoAtexp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoPandora$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    especificoTestesOTK$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoChatuba$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    especificoChatubaTodosColaboradores$: Observable<boolean>;

    especificoConectaCargo$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    especificoMcSupply$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    especificoPostoTulio$: Observable<boolean>;
    especificoGrupoDikma$: Observable<boolean>;
    especificoUnasp$: Observable<boolean>;
    especificoExperimentacao$: Observable<boolean>;
    especificoPoiesis$: Observable<boolean>;
    especificoAltaGenetics$: Observable<boolean>;
    especificoRijk$: Observable<boolean>;
    especificoEquinor$: Observable<boolean>;
    ambienteDemo: boolean;

    constructor(
        private _appDictionaryService: AppDictionaryService,
        private _http: HttpClient,
        private _storeService: AuthStoreService
    ) {
        this._usuario$ = this._storeService.getUser();
        this._usuarioInterno$ = this._storeService.getUserInternal();

        this.erro$ = this._storeService.getError();
        this.aviso$ = this._storeService.getWarning();
        this.sucesso$ = this._storeService.getSuccess();

        this.autenticado$ = this._storeService.getUserIsAuthenticated();
        this.processando$ = this._storeService.getIsProcessing();

        this.moduloCadastros$ = this._storeService.getModuloCadastros();
        this.permissoesCadastros$ = this._storeService.getPermissoesCadastros();

        this.moduloCrm$ = this._storeService.getModuloCrm();
        this.permissoesCrm$ = this._storeService.getPermissoesCrm();

        this.moduloVendas$ = this._storeService.getModuloVendas();
        this.permissoesVendas$ = this._storeService.getPermissoesVendas();

        this.moduloFinanceiro$ = this._storeService.getModuloFinanceiro();
        this.permissoesFinanceiro$ =
            this._storeService.getPermissoesFinanceiro();

        this.moduloCompras$ = this._storeService.getModuloCompras();
        this.permissoesCompras$ = this._storeService.getPermissoesCompras();

        this.moduloEstoque$ = this._storeService.getModuloEstoque();
        this.moduloEstoqueMovimentacoes$ =
            this._storeService.getModuloEstoqueMovimentacoes();
        this.permissoesEstoque$ = this._storeService.getPermissoesEstoque();

        this.moduloSistema$ = this._storeService.getModuloSistema();
        this.permissoesSistema$ = this._storeService.getPermissoesSistema();

        this.responsavelCompras$ = this.permissoesCompras$.pipe(
            map((r) => r.responsavel),
            filter((r) => r == true || r == false)
        );
        this.responsavelEstoque$ = this.permissoesEstoque$.pipe(
            map((r) => r.responsavel),
            filter((r) => r == true || r == false)
        );
        this.responsavelCrm$ = this.permissoesCrm$.pipe(
            map((r) => r.responsavel),
            filter((r) => r == true || r == false)
        );
        this.responsavelVendas$ = this.permissoesVendas$.pipe(
            map((r) => r.responsavel),
            filter((r) => r == true || r == false)
        );
        this.responsavelFinanceiro$ = this.permissoesFinanceiro$.pipe(
            map((r) => r.responsavel),
            filter((r) => r == true || r == false)
        );

        // ESPECIFICO OTK
        this._storeService
            .getUser()
            .pipe(map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 1))
            .subscribe((r: boolean) => {
                this.especificoOTK$.next(r);
            });

        // ESPECIFICO 903
        this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 903)
            )
            .subscribe((r: boolean) => {
                this.especifico903$.next(r);
            });

        // ESPECIFICO AFIXGRAF
        this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 40)
            )
            .subscribe((r: boolean) => {
                this.especificoAfixGraf$.next(r);
            });

        // VERSAO DEV (EMP: 902 ; 9000)
        this._storeService
            .getUser()
            .pipe(filter((r: AuthUser) => r.autenticado))
            .subscribe((r: any) => {
                if (r.codigoDaEmpresa == 902 || r.codigoDaEmpresa == 9000) {
                    console.log('** ESPECIFICO VERSAO DEV **');
                    this.versaoDEV$.next(true);
                    this.especificoTestesOTK$.next(true);
                }
            });

        // // VERSAO BETA
        // this._storeService.getUser()
        //     .pipe(
        //         filter((r: AuthUser) => r.autenticado)
        //     )
        //     .subscribe(
        //         (r: any) => {
        //             if (r.codigoDaEmpresa == 902 ||
        //                 r.codigoDaEmpresa == 9000) {
        //                 console.log("** ESPECIFICO VERSAO BETA - CLIENTE **");
        //                 this.versaoBETA$.next(true);
        //                 return;
        //             }

        //         }
        //     );

        // ESPECIFICO: AOCEP
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2385
                )
            )
            .subscribe((r: AuthUser) => {
                console.info('** ESPECIFICO AOCEP - CLIENTE **');
                this.especificoAocep$.next(true);
            });

        // ESPECIFICO: BRADESCO
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2417
                )
            )
            .subscribe((r: AuthUser) => {
                console.info('** ESPECIFICO BRADESCO - CLIENTE **');
                this.especificoBradesco$.next(true);
            });

        // ESPECIFICO: CUIABA MADEIRAS
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2431
                )
            )
            .subscribe((r: AuthUser) => {
                console.info('** ESPECIFICO CUIABA MADEIRAS - CLIENTE **');
                this.especificoCuiabaMadeiras$.next(true);
            });

        // ESPECIFICO: 5A
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 1532
                )
            )
            .subscribe((r: AuthUser) => {
                console.info('** ESPECIFICO 5A - CLIENTE **');
                this.especifico5A$.next(true);
            });

        // ESPECIFICO: MINDSET
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2449
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO MINDSET - CLIENTE **');
                this.especificoMindset$.next(true);
            });

        // ESPECIFICO: MGM
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2284
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO MGM - CLIENTE **');
                this.especificoMGM$.next(true);
            });

        // ESPECIFICO: G2C
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2170
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO G2C - CLIENTE **');
                this.especificoG2C$.next(true);
            });

        // ESPECIFICO: LEAN INSTITURE
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2513
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO LEAN INSTITUE - CLIENTE **');

                this.especificoLeanInstitute$.next(true);
            });

        // ESPECIFICO: TREVISO
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2491
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO TREVISO - CLIENTE **');
                this.especificoTreviso$.next(true);
            });

        // ESPECIFICO: COLYMAR
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2475
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO COLYMAR - CLIENTE **');
                this.especificoColymar$.next(true);
            });

        // ESPECIFICO: BASILE
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2288
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO BASILE - CLIENTE **');
                this.especificoBasile$.next(true);
            });

        this.especificoPostoTulio$ = this._storeService
            .getUser()
            .pipe(
                map(
                    (r: AuthUser) =>
                        r.administrador && r.codigoDaEmpresa == 2072
                )
            );

        this.especificoGrupoDikma$ = this._storeService
            .getUser()
            .pipe(
                map(
                    (r: AuthUser) =>
                        r.administrador && r.codigoDaEmpresa == 2373
                )
            );

        this.especificoExperimentacao$ = this._storeService
            .getUser()
            .pipe(map((r: AuthUser) => r.codigoDaEmpresa > 9000));

        this.especificoUnasp$ = this._storeService
            .getUser()
            .pipe(
                map(
                    (r: AuthUser) =>
                        r.administrador && r.codigoDaEmpresa == 2481
                )
            );

        this.especificoChatubaTodosColaboradores$ = this._storeService
            .getUser()
            .pipe(map((r: AuthUser) => r.codigoDaEmpresa == 2321));

        this.especificoRijk$ = this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2136)
            );
        this.especificoEquinor$ = this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2419)
            );
        this.especificoPoiesis$ = this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2308)
            );

        this.especificoAltaGenetics$ = this._storeService
            .getUser()
            .pipe(
                map((r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2397)
            );

        // ESPECIFICO: CHATUBA
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2321
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO CHATUBA - CLIENTE **');
                this.especificoChatuba$.next(true);
            });

        // ESPECIFICO: FONTESUL
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2518
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO FONTESUL - CLIENTE **');
                this.especificoFontesul$.next(true);
            });

        // ESPECIFICO: MATERHOUSE
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2526
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO MATERHOUSE - CLIENTE **');
                this.especificoMaterhouse$.next(true);
            });

        // ESPECIFICO: WORK PLANNING
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 1005
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO WORK PLANNING - CLIENTE **');
                this.especificoWorkPlanning$.next(true);
            });

        // ESPECIFICO: GRUPO URSINI
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2531
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO GRUPO URSINI - CLIENTE **');
                this.especificoGrupoUrsini$.next(true);
            });

        // ESPECIFICO: MERVIL
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2319
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO MERVIL - CLIENTE **');
                this.especificoMervil$.next(true);
            });

        // ESPECIFICO: GENEROS
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2255
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO GENEROS - CLIENTE **');
                this.especificoGENEROS$.next(true);
            });

        // ESPECIFICO: SERVCRED
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2035
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO SERVCRED - CLIENTE **');
                this.especificoSERVCRED$.next(true);
            });

        // ESPECIFICO: ATEXP
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) =>
                        r.administrador && r.codigoDaEmpresa == 2224
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO ATEXTP - CLIENTE **');
                this.especificoAtexp$.next(true);
            });

        // ESPECIFICO: RX
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2501
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO RX - CLIENTE **');
                this.especificoRX$.next(true);
            });

        // ESPECIFICO: NOVA VIDA
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 1673
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO NOVA VIDA - CLIENTE **');
                this.especificoNovaVida$.next(true);
            });

        // ESPECIFICO: WEK
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2521
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO WEK - CLIENTE **');
                this.especificoWEK$.next(true);
            });

        // ESPECIFICO: CONECTA CARGO
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2535
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO CONECTA CARGO - CLIENTE **');
                this.especificoConectaCargo$.next(true);
            });

        // ESPECIFICO: PANDORA
        this._storeService
            .getUser()
            .pipe(
                filter(
                    (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 9000
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO PANDORA - CLIENTE **');
                this.especificoPandora$.next(true);

                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.vendedores,
                    'Associados'
                );

                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.codigoDoVendedor,
                    'Associados'
                );
            });

        // ESPECIFICO: MC SUPPLY
        this._storeService
            .getUser()
            .pipe(
                filter(
                    // (r: AuthUser) => r.autenticado && r.codigoDaEmpresa == 2554
                    (r: AuthUser) =>
                        r.autenticado &&
                        (r.codigoDaEmpresa == 2554 || r.codigoDaEmpresa == 9000)
                ),
                take(1)
            )
            .subscribe(() => {
                console.info('** ESPECIFICO MC SUPPLY - CLIENTE **');
                this.especificoMcSupply$.next(true);
            });

        this.especificoBradesco$
            .pipe(filter((r: boolean) => r == true))
            .subscribe(() => {
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.projetos,
                    'Ambientes'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.codigoDoProjeto,
                    'Ambiente'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.codigoDoProjetoOrigem,
                    'Ambiente Origem'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.codigoDoProjetoDestino,
                    'Ambiente Destino'
                );
            });

        this.especificoRijk$
            .pipe(
                filter((r: boolean) => r == true),
                take(1)
            )
            .subscribe(() => {
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.classificacaoAuxiliar,
                    'Crop'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.codigoDaClassificacaoAuxiliar,
                    'Crop'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.descricaoDaClassificacaoAuxiliar,
                    'Crop'
                );
                this._appDictionaryService.addOrUpdate(
                    dictionaryEnum.observacaoAuxiliar,
                    'Área Utilizada'
                );
            });

        this._usuario$.subscribe((usr: AuthUser) => {
            if (AppSettings.APP_DEV_MODE) {
                console.log('[DEV] AUTH USR -->', usr);
            }

            this.administrador$.next(usr && usr.administrador);

            this.usuarioOperador$.next(usr && usr.usuarioOperador);

            this.tratamentoPerfilClienteNovo$.next(
                usr ? usr.tratamentoPerfilClienteNovo : true
            );
            this.exibirAvisoRequisitanteOperadorAntigo$.next(
                usr && usr.exibirAvisoRequisitanteOperadorAntigo
            );

            this.executarFluxoInicialDemonstracao$.next(
                usr && usr.executarFluxoInicialDemonstracao
            );

            this.loginInterno$.next(usr && usr.loginInterno);

            this.executarFluxoInicialDemonstracao$.next(
                usr && usr.executarFluxoInicialDemonstracao
            );

            this.executarCarregarBaseModeloDemonstracao$.next(
                usr && usr.executarCarregarBaseModeloDemonstracao
            );

            this.ambienteDemonstracao$.next(usr && usr.ambienteDemonstracao);

            this.hasBanner$.next(usr && usr.ambienteDemonstracao);

            this.ambienteDemo = usr.ambienteDemonstracao;

            this.loginInternoColaboradorNaoDefinido$.next(
                usr && usr.loginInterno && usr.codigoDoColaborador == 0
            );

            if (!usr) {
                AppSettings.SetIsFlaggedMode(false);
                this.usuarioAutenticado$.next(null);
                return;
            }

            if (!usr.codigoDoUsuario) {
                AppSettings.SetIsFlaggedMode(false);
                this.usuarioAutenticado$.next(null);
                return;
            }

            if (usr.codigoDaEmpresa > 0 && usr.autenticado) {
                AppSettings.SetIsFlaggedMode(usr.isFlaggedMode);
                this.usuarioAutenticado$.next(usr);
                return;
            }

            AppSettings.SetIsFlaggedMode(false);
            this.usuarioAutenticado$.next(null);
        });

        this._usuarioInterno$.subscribe((usr: any) => {
            this.usuarioInterno$.next(usr);
        });

        AppSettings.token$.subscribe((r: any) => {
            if (!r) {
                this.invalidAuth$.next();
            }
        });

        AppSettings.unauthorized$.subscribe(() => this.invalidAuth$.next());
    }

    limparToken(): void {
        AppSettings.limparToken();
        this._storeService.dispatchLimparAction();
    }

    recuperarLogin(force: boolean = false): void {
        if (!force) {
            if (this.loginAutenticado) {
                return;
            }
        }

        let token = AppSettings.recuperarToken();
        if (!token) {
            this._storeService.dispatchLimparAction();
            return;
        }

        this._storeService.dispatchRecuperarLogin(token);
    }

    refreshToken(): void {
        const token = AppSettings.recuperarToken();
        if (!token) {
            this._storeService.dispatchLimparAction();
            return;
        }

        this._storeService.dispatchRefreshToken(token);
    }

    efetuarLogout(): void {
        this._storeService.dispatchRegistrarLogout();
        this._storeService.dispatchEfetuarLogout();
        this.limparToken();
    }

    registrarLogin(): void {
        this._storeService.dispatchRegistrarLogin();
    }

    get administrador(): boolean {
        return this.usuarioAutenticado$.value
            ? this.usuarioAutenticado$.value.administrador
            : false;
    }

    get loginAutenticado(): boolean {
        return this.usuarioAutenticado$.value ? true : false;
    }

    get codigoDaEmpresa(): number {
        return this.usuarioAutenticado$.value
            ? this.usuarioAutenticado$.value.codigoDaEmpresa
            : null;
    }

    perfilDeAcessoAntigo(): boolean {
        return this.usuarioAutenticado$.value &&
            this.usuarioAutenticado$.value.codigoDaEmpresa < 2477
            ? true
            : false;
    }

    get codigoDoUsuario(): string {
        return this.usuarioAutenticado$.value
            ? this.usuarioAutenticado$.value.codigoDoUsuario
            : null;
    }

    get codigoDoColaborador(): number {
        return this.usuarioAutenticado$.value
            ? this.usuarioAutenticado$.value.codigoDoColaborador
            : null;
    }

    get filiaisDoColaborador(): number[] {
        return this.usuarioAutenticado$.value
            ? this.usuarioAutenticado$.value.filiaisDoColaborador
            : [];
    }

    get usuario(): any {
        return this.usuarioAutenticado$.value;
    }

    get codigoDaFilial(): number {
        return this.usuarioAutenticado$.value.codigoDaFilial;
    }

    get codigoDoCentroDeCusto(): string {
        return this.usuarioAutenticado$.value.codigoDoCentroDeCusto;
    }

    autenticar(email: string, senha: string): void {
        this._storeService.dispatchAutenticarLogin(email, senha);
    }

    autenticarLoginBaseOTK(email: string, senha: string): void {
        this._storeService.dispatchAutenticarLoginBaseOTK(email, senha);
    }

    autenticarLoginBaseAfixGraf(email: string, senha: string): void {
        this._storeService.dispatchAutenticarLoginBaseAfixGraf(email, senha);
    }

    autenticarLoginInterno(
        email: string,
        senha: string,
        codigoDaEmpresa: number,
        codigoDoUsuario: string
    ): void {
        this._storeService.dispatchAutenticarLoginInterno(
            email,
            senha,
            codigoDaEmpresa,
            codigoDoUsuario
        );
    }

    autenticarLoginInternoAdmin(
        email: string,
        senha: string,
        codigoDaEmpresa: number
    ): void {
        this._storeService.dispatchAutenticarLoginInternoAdmin(
            email,
            senha,
            codigoDaEmpresa
        );
    }

    autenticarLoginCliente(id: string, key: string, username: string): void {
        this._storeService.dispatchAutenticarLoginCliente(id, key, username);
    }

    autenticarLoginBradesco(upn: string, senha: string): void {
        this._storeService.dispatchAutenticarLoginBradesco(upn, senha);
    }

    enviarNovaSenha(email: string): void {
        this._storeService.dispatchEnviarNovaSenha(email);
    }

    criarNovaConta(obj: any): void {
        this._storeService.dispatchCriarNovaConta(obj);
    }

    confirmarEmail(email: string, novaSenha: string, token: string): void {
        this._storeService.dispatchConfirmarEmailSenha(email, novaSenha, token);
    }

    redefinirSenha(email: string, novaSenha: string, token: string): void {
        this._storeService.dispatchRedefinirSenha(email, novaSenha, token);
    }

    getAvisos(): Observable<any> {
        return this._http.get<any>(`${this.avisosURL}/avisos`);
    }
}
