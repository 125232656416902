export enum StateModeEnum {
    undefined = 0,

    insert = 1,
    edit = 2,
    browse = 4,
    selected = 5,
    modified = 6,
    removed = 7,
}

export enum UnidadeMedidaEnum {
    QTDE = 'QTDE',
    METRO = 'M',
    CENTIMETRO = 'CM',
}

export enum GridNameEnum {
    OTKWEB_AGENCIA = 'OTKWEB_AGENCIA_V1',
    OTKWEB_AJUSTE_ESTOQUE = 'OTKWEB_AJUSTE_ESTOQUE_V1',
    OTKWEB_ALMOXARIFADO = 'OTKWEB_ALMOXARIFADO_V1',
    OTKWEB_BANCO = 'OTKWEB_BANCO_V1',
    OTKWEB_BASE_FORNECEDORES = 'OTKWEB_BASE_FORNECEDORES_V1',
    OTKWEB_CAIXA_BANCOS = 'OTKWEB_CAIXA_BANCOS_V1',
    OTKWEB_CARTEIRA = 'OTKWEB_CARTEIRA_V1',
    OTKWEB_CATEGORIA_CLIENTE = 'OTKWEB_CATEGORIA_CLIENTE_V1',
    OTKWEB_CENTRO_DE_CUSTO = 'OTKWEB_CENTRO_DE_CUSTO_V1',
    OTKWEB_CENTRO_DE_CUSTO_GRUPO = 'OTKWEB_CENTRO_DE_CUSTO_GRUPO_V1',
    OTKWEB_CFOP = 'OTKWEB_CFOP_V1',
    OTKWEB_CLASSIFICACAO_AUXILIAR = 'OTKWEB_CLASSIFICACAO_AUXILIAR_V1',
    OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE = 'OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE_V1',
    OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS = 'OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_CODIGO_DE_SERVICO = 'OTKWEB_CODIGO_DE_SERVICO_V1',
    OTKWEB_COLABORADOR_USUARIO = 'OTKWEB_COLABORADOR_USUARIO_V1',
    OTKWEB_COLABORADOR_REQUISITANTE = 'OTKWEB_COLABORADOR_REQUISITANTE_V1',
    OTKWEB_COLABORADOR_GRUPO = 'OTKWEB_COLABORADOR_GRUPO_V1',
    OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS = 'OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS_V1',
    OTKWEB_CONTA_CORRENTE = 'OTKWEB_CONTA_CORRENTE_V1',
    OTKWEB_CONTAS_PAGAR = 'OTKWEB_CONTAS_PAGAR_V1',
    OTKWEB_CONTAS_PAGAR_BX = 'OTKWEB_CONTAS_PAGAR_BX_V1',
    OTKWEB_CONTAS_PAGAR_RATEIOS = 'OTKWEB_CONTAS_PAGAR_RATEIOS_V1',
    OTKWEB_CONTAS_PAGAR_PESQUISAR = 'OTKWEB_CONTAS_PAGAR_PESQUISAR_V1',
    OTKWEB_CONTAS_RECEBER = 'OTKWEB_CONTAS_RECEBER_V1',
    OTKWEB_CONTAS_RECEBER_RATEIOS = 'OTKWEB_CONTAS_RECEBER_RATEIOS_V1',
    OTKWEB_CONTAS_RECEBER_BX = 'OTKWEB_CONTAS_RECEBER_BX_V1',
    OTKWEB_CONTAS_RECEBER_PREJUIZOS = 'OTKWEB_CONTAS_RECEBER_PREJUIZOS_V1',
    OTKWEB_CONTAS_RECEBER_PESQUISAR = 'OTKWEB_CONTAS_RECEBER_PESQUISAR_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_TIPO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_TPO_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS_V1',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS_V1',
    OTKWEB_COTACAO_SELECAO_FORNECEDORES = 'OTKWEB_COTACAO_SELECAO_FORNECEDORES_V1',
    OTKWEB_COTACOES_FORNECEDOR = 'OTKWEB_COTACOES_FORNECEDOR_V1',
    OTKWEB_COTACOES_ITENS_GRID = 'OTKWEB_COTACOES_ITENS_GRID_V1',
    OTKWEB_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_CR_CP_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_CRM_CLIENTES = 'OTKWEB_CRM_CLIENTES_V1',
    OTKWEB_CRM_CLIENTES_CONTAS_RECEBER = 'OTKWEB_CRM_CLIENTES_CONTAS_RECEBER_V1',
    OTKWEB_CRM_CLIENTES_CONTATO = 'OTKWEB_CRM_CLIENTES_CONTATO_V1',
    OTKWEB_CRM_CLIENTES_PESQUISAR = 'OTKWEB_CRM_CLIENTES_PESQUISAR_V1',
    OTKWEB_CRM_TIPO_CLIENTES = 'OTKWEB_CRM_TIPO_CLIENTES_V1',
    OTKWEB_CRM_SITUACAO_CLIENTES = 'OTKWEB_CRM_SITUACAO_CLIENTES_V1',
    OTKWEB_CUSTO_ABC = 'OTKWEB_CUSTO_ABC_V1',
    OTKWEB_DUPLICATA_COMPRAS = 'OTKWEB_DUPLICATA_COMPRAS_V1',
    OTKWEB_DUPLICATA_VENDAS = 'OTKWEB_DUPLICATA_VENDAS_V1',
    OTKWEB_DUPLICATA_HISTORICO_CP = 'OTKWEB_DUPLICATA_HISTORICO_CP_V1',
    OTKWEB_DUPLICATA_HISTORICO_CR = 'OTKWEB_DUPLICATA_HISTORICO_CR_V1',
    OTKWEB_ENDERECO_DE_ENTREGA = 'OTKWEB_ENDERECO_DE_ENTREGA_V1',
    OTKWEB_ENTRADA_DE_ESTOQUE = 'OTKWEB_ENTRADA_DE_ESTOQUE_V1',
    OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_ENTRADA_LOTE_ABERTAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS_V1',
    OTKWEB_ENTRADA_LOTE_ENCERRADAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS_V1',
    OTKWEB_ENTRADA_LOTE_ITENS = 'OTKWEB_ENTRADA_LOTE_ITENS_V1',
    OTKWEB_EXPEDICAO_CANCELADOS = 'OTKWEB_EXPEDICAO_CANCELADOS_V1',
    OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR = 'OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_EXPEDICAO_PENDENTES = 'OTKWEB_EXPEDICAO_PENDENTES_V1',
    OTKWEB_EXPEDICAO_EM_PROCESSAMENTO = 'OTKWEB_EXPEDICAO_EM_PROCESSAMENTO_V1',
    OTKWEB_EXPEDICAO_PROCESSADOS = 'OTKWEB_EXPEDICAO_PROCESSADOS_V1',
    OTKWEB_EXPORTACAO_DE_RELATORIO = 'OTKWEB_EXPORTACAO_DE_RELATORIO_V1',

    OTKWEB_EXPEDICAO_POR_REQUISICAO = 'OTKWEB_EXPEDICAO_POR_REQUISICAO_V1',
    OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE = 'OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE_V1',

    OTKWEB_FATURA_ABERTAS = 'OTKWEB_FATURA_ABERTAS_V1',
    OTKWEB_FATURA_CANCELADAS = 'OTKWEB_FATURA_CANCELADAS_V1',
    OTKWEB_FATURA_PROCESSADAS = 'OTKWEB_FATURA_PROCESSADAS_V1',
    OTKWEB_FATURA_SERVICOS = 'OTKWEB_FATURA_SERVICOS_V1',

    OTKWEB_FILIAL = 'OTKWEB_FILIAL_V1',
    OTKWEB_FINANCEIRO_CAIXA_BANCOS = 'OTKWEB_FINANCEIRO_CAIXA_BANCOS_V1',
    OTKWEB_FINANCEIRO_CAIXA_CP = 'OTKWEB_FINANCEIRO_CAIXA_CP_V1',
    OTKWEB_FINANCEIRO_CAIXA_CR = 'OTKWEB_FINANCEIRO_CAIXA_CR_V1',
    OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS = 'OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS_V1',
    OTKWEB_CONDICAO_DE_PAGAMENTO = 'OTKWEB_CONDICAO_DE_PAGAMENTO_V1',
    OTKWEB_MAPEAMENTO_DE_RATEIO = 'OTKWEB_MAPEAMENTO_DE_RATEIO_V1',
    OTKWEB_FORNECEDOR = 'OTKWEB_FORNECEDOR_V1',
    OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS = 'OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_FORNECEDOR_CONTATO = 'OTKWEB_FORNECEDOR_CONTATO_V1',
    OTKWEB_FORNECEDOR_COTACOES = 'OTKWEB_FORNECEDOR_COTACOES_V1',
    OTKWEB_FORNECEDOR_CP = 'OTKWEB_FORNECEDOR_CP_V1',
    OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA = 'OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA_V1',
    OTKWEB_FORNECEDOR_PESQUISAR = 'OTKWEB_FORNECEDOR_PESQUISAR_V1',
    OTKWEB_SEGMENTO = 'OTKWEB_SEGMENTO_V1',
    OTKWEB_RAMO_DE_ATIVIDADE = 'OTKWEB_RAMO_DE_ATIVIDADE_V1',
    OTKWEB_FORNECEDOR_TIPO = 'OTKWEB_FORNECEDOR_TIPO_V1',
    OTKWEB_GRUPO_LANCAMENTO = 'OTKWEB_GRUPO_LANCAMENTO_V1',
    OTKWEB_ITEM_LANCAMENTO = 'OTKWEB_ITEM_LANCAMENTO_V1',
    OTKWEB_MAPEAMENTO_PRODUTO = 'OTKWEB_MAPEAMENTO_PRODUTO_V1',
    OTKWEB_MOTIVO_DA_COMPRA = 'OTKWEB_MOTIVO_DA_COMPRA_V1',
    OTKWEB_MOTIVO_REQUISICAO = 'OTKWEB_MOTIVO_REQUISICAO_V1',
    OTKWEB_NCM = 'OTKWEB_NCM_V1',
    OTKWEB_NIVEL_DE_APROVACAO = 'OTKWEB_NIVEL_DE_APROVACAO_V1',
    OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE = 'OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_ITENS = 'OTKWEB_NOTA_FISCAL_PRODUTO_ITENS_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS = 'OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES = 'OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES_V1',
    OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS_V1',
    OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS_V1',
    OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES = 'OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES_V1',
    OTKWEB_ORDEM_DE_COMPRA_ABERTAS = 'OTKWEB_ORDEM_DE_COMPRA_ABERTAS_V1',
    OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS = 'OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES_V2 = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_V2',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO_V1',
    OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS = 'OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS_V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS__V1  V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS_V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS_V1',
    OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO_V1',
    OTKWEB_ORDEM_DE_COMPRA_PESQUISAR = 'OTKWEB_ORDEM_DE_COMPRA_PESQUISAR_V1',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_V1',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA_V1',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO_V1',
    OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES = 'OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES_V1',
    OTKWEB_ORDEM_PRODUTO = 'OTKWEB_ORDEM_PRODUTO_V1',
    OTKWEB_ORDEM_SERVICOS_ABERTAS = 'OTKWEB_ORDEM_SERVICOS_ABERTAS_V1',
    OTKWEB_ORDEM_SERVICOS_CANCELADAS = 'OTKWEB_ORDEM_SERVICOS_CANCELADAS_V1',
    OTKWEB_ORDEM_SERVICOS_PROCESSADAS = 'OTKWEB_ORDEM_SERVICOS_PROCESSADAS_V1',
    OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL = 'OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL_V1',
    OTKWEB_PAIS = 'OTKWEB_PAIS_V1',
    OTKWEB_PARCELAS_PEDIDO_DE_COMPRA = 'OTKWEB_PARCELAS_PEDIDO_DE_COMPRA_V1',
    OTKWEB_PEDIDO_DE_VENDA_ABERTAS = 'OTKWEB_PEDIDO_DE_VENDA_ABERTAS_V1',
    OTKWEB_PEDIDO_DE_VENDA_CANCELADAS = 'OTKWEB_PEDIDO_DE_VENDA_CANCELADAS_V1',
    OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS = 'OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS_V1',
    OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS = 'OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_ABERTAS = 'OTKWEB_PEDIDO_DE_COMPRA_ABERTAS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS = 'OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_ITENS = 'OTKWEB_PEDIDO_DE_COMPRA_ITENS_V1',
    OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR = 'OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR_V1',
    OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL = 'OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL_V1',
    OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO_V1',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL_V1',
    OTKWEB_PLANO_DE_CONTAS = 'OTKWEB_PLANO_DE_CONTAS_V1',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO_V1',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS_V1',
    OTKWEB_PREVISAO_CONTAS_PAGAR = 'OTKWEB_PREVISAO_CONTAS_PAGAR_V1',
    OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS_V1',
    OTKWEB_PREVISAO_CONTAS_RECEBER = 'OTKWEB_PREVISAO_CONTAS_RECEBER_V1',
    OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS_V1',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO_V1',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS_V1',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA_V1',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS_V1',
    OTKWEB_PREVISAO_HISTORICO_CP = 'OTKWEB_PREVISAO_HISTORICO_CP_V1',
    OTKWEB_PREVISAO_HISTORICO_CR = 'OTKWEB_PREVISAO_HISTORICO_CR_V1',
    OTKWEB_PRODUTO = 'OTKWEB_PRODUTO_V1',
    OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS = 'OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_PRODUTO_CATEGORIA = 'OTKWEB_PRODUTO_CATEGORIA_V1',
    OTKWEB_PRODUTO_ESTOQUE = 'OTKWEB_PRODUTO_ESTOQUE_V1',
    OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS = 'OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS_V1',
    OTKWEB_PRODUTO_GRUPO = 'OTKWEB_PRODUTO_GRUPO_V1',
    OTKWEB_PRODUTO_MOVIMENTACAO = 'OTKWEB_PRODUTO_MOVIMENTACAO_V1',
    OTKWEB_PRODUTO_PEDIDOS_COMPRAS = 'OTKWEB_PRODUTO_PEDIDOS_COMPRAS_V1',
    OTKWEB_PRODUTO_PESQUISAR = 'OTKWEB_PRODUTO_PESQUISAR_V1',
    OTKWEB_PRODUTO_SERIES_LOTE = 'OTKWEB_PRODUTO_SERIES_LOTE_V1',
    OTKWEB_PRODUTO_SUGESTAO_COMPRAS = 'OTKWEB_PRODUTO_SUGESTAO_COMPRAS_V1',

    OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA = 'OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA_V1',

    OTKWEB_PROJETO = 'OTKWEB_PROJETO_V1',
    OTKWEB_PROJETO_ARQUIVOS_ANEXADOS = 'OTKWEB_PROJETO_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_PROJETO_MOVIMENTACAO = 'OTKWEB_PROJETO_MOVIMENTACAO_V1',
    OTKWEB_PROJETO_PEDIDO_DE_COMPRA = 'OTKWEB_PROJETO_PEDIDO_DE_COMPRA_V1',
    OTKWEB_PROJETO_CONTAS_PAGAR = 'OTKWEB_PROJETO_CONTAS_PAGAR_V1',
    OTKWEB_PROJETO_CONTAS_RECEBER = 'OTKWEB_PROJETO_CONTAS_RECEBER_V1',
    OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR = 'OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR_V1',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS_V1',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES_V1',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS_V1',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO_V1',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE_V1',
    OTKWEB_REGRA_DE_APROVACAO = 'OTKWEB_REGRA_DE_APROVACAO_V1',
    OTKWEB_REGRA_DE_APROVACAO_OC = 'OTKWEB_REGRA_DE_APROVACAO_OC_V1',
    OTKWEB_REGRA_DE_APROVACAO_OL = 'OTKWEB_REGRA_DE_APROVACAO_OL_V1',
    OTKWEB_REGRA_DE_APROVACAO_PC = 'OTKWEB_REGRA_DE_APROVACAO_PC_V1',
    OTKWEB_REGRA_DE_APROVACAO_RC = 'OTKWEB_REGRA_DE_APROVACAO_RC_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_ABERTAS = 'OTKWEB_REQUISICAO_ABERTAS_DE_COMPRAS_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_ITENS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ITENS_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_ENCERRADAS = 'OTKWEB_REQUISICAO_ENCERRADAS_DE_COMPRAS_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR = 'OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_POR_PRODUTO = 'OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_POR_PRODUTO',
    OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO = 'OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR = 'OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_REQUISICAO_PRODUTO = 'OTKWEB_REQUISICAO_PRODUTO_V1',
    OTKWEB_SAIDA_DE_ESTOQUE = 'OTKWEB_SAIDA_DE_ESTOQUE_V1',
    OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_SALDOS_EM_ESTOQUE = 'OTKWEB_SALDOS_EM_ESTOQUE_V1',
    OTKWEB_SECAO = 'OTKWEB_SECAO_V1',
    OTKWEB_SUGESTAO_DE_COMPRAS = 'OTKWEB_SUGESTAO_DE_COMPRAS_V1',
    OTKWEB_TIPO_DE_COMPRA = 'OTKWEB_TIPO_DE_COMPRA_V1',
    OTKWEB_TIPO_DE_DOCUMENTO = 'OTKWEB_TIPO_DE_DOCUMENTO_V1',
    OTKWEB_TIPO_DE_PAGAMENTO = 'OTKWEB_TIPO_DE_PAGAMENTO_V1',
    OTKWEB_TIPO_PRODUTO = 'OTKWEB_TIPO_PRODUTO_V1',
    OTKWEB_TIPO_REQUISICAO = 'OTKWEB_TIPO_REQUISICAO_V1',
    OTKWEB_TRANSFERENCIA_EM_LOTE_ESTOQUE = 'OTKWEB_TRANSFERENCIA_EM_LOTE_V1',
    OTKWEB_TRANSFERENCIAS = 'OTKWEB_TRANSFERENCIAS_V1',
    OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR_V1',
    OTKWEB_UNIDADE_MEDIDA = 'OTKWEB_UNIDADE_MEDIDA_V1',
    OTKWEB_VENDAS_FATURA_PESQUISAR = 'OTKWEB_VENDAS_FATURA_PESQUISAR_V1',
    OTKWEB_VENDAS_NFE_PESQUISAR = 'OTKWEB_VENDAS_NFE_PESQUISAR_V1',
    OTKWEB_VENDAS_NFSE_PESQUISAR = 'OTKWEB_VENDAS_NFSE_PESQUISAR_V1',
    OTKWEB_VENDAS_ORDEM_PESQUISAR = 'OTKWEB_VENDAS_ORDEM_PESQUISAR_V1',
    OTKWEB_VENDAS_PEDIDO_PESQUISAR = 'OTKWEB_VENDAS_PEDIDO_PESQUISAR_V1',
    OTKWEB_VENDAS_ORDEM_SERVICOS = 'OTKWEB_VENDAS_ORDEM_SERVICOS_V1',
    OTKWEB_VENDAS_PIS = 'OTKWEB_VENDAS_PIS_V1',
    OTKWEB_VENDAS_VENDEDOR = 'OTKWEB_VENDAS_VENDEDOR_V1',
    OTKWEB_COFINS = 'OTKWEB_COFINS_V1',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID_V1',
    OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS = 'OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS_V1',
    OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS = 'OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS_V1',
    OTKWEB_VENDAS_NOTA_FISCAL_SERVICOS = 'OTKWEB_VENDAS_NOTA_FISCAL_SERVICO_V1',
    OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA = 'OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA_V1',
    OTKWEB_COTACAO_COMPARACAO = 'OTKWEB_COTACAO_COMPARACAO_V1',
    OTKWEB_MAPA_DE_COTACOES = 'OTKWEB_MAPA_DE_COTACOES_V1',
    OTKWEB_PEDIDO_DE_COMPRA_RATEIOS = 'OTKWEB_PEDIDO_DE_COMPRA_RATEIOS_V1',
    OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO = 'OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO_V1',
    OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA = 'OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA_V1',
    OTKWEB_GRUPO_DE_CLIENTE = 'OTKWEB_GRUPO_DE_CLIENTE_V1',
    OTKWEB_CRM_CLIENTES_FATURAS = 'OTKWEB_CRM_CLIENTES_FATURAS_V1',
    OTKWEB_FINANCEIRO_EXTRATO = 'OTKWEB_FINANCEIRO_EXTRATO_V1',
    OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS = 'OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS_V1',
    OTKWEB_FINANCEIRO_EXTRATO_CRCP = 'OTKWEB_FINANCEIRO_EXTRATO_CRCP_V1',
    OTKWEB_FINANCEIRO_PAGAMENTOS = 'OTKWEB_FINANCEIRO_PAGAMENTOS_V1',
    OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA = 'OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA_V1',
    OTKWEB_PRODUTO_PREVISAO_SALDO = 'OTKWEB_PRODUTO_PREVISAO_SALDO_V1',
    OTKWEB_PRODUTO_MARGEM_LUCRO = 'OTKWEB_PRODUTO_MARGEM_LUCRO_V1',
    OTKWEB_CONFIGURACAO_VENDAS = 'OTKWEB_CONFIGURACAO_VENDAS_V1',
    OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS = 'OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS_V1',
    OTKWEB_BRADESCO_QUESTIONARIO = 'OTKWEB_BRADESCO_QUESTIONARIO_V1',
    OTKWEB_TABELA_IBPT = 'OTKWEB_TABELA_IBPT_V1',
    OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO_V1',
    OTKWEB_ORCAMENTOS_ABERTOS = 'OTKWEB_ORCAMENTOS_ABERTOS_V1',
    OTKWEB_ORCAMENTOS_CANCELADOS = 'OTKWEB_ORCAMENTOS_CANCELADOS_V1',
    OTKWEB_ORCAMENTOS_PROCESSADOS = 'OTKWEB_ORCAMENTOS_PROCESSADOS_V1',
    OTKWEB_ORCAMENTOS_PRODUTOS = 'OTKWEB_ORCAMENTOS_PRODUTOS_V1',
    OTKWEB_NOTA_RECEBIDAS = 'OTKWEB_NOTA_RECEBIDAS_V1',
    OTKWEB_NOTA_RECEBIDAS_PRODUTOS = 'OTKWEB_NOTA_RECEBIDAS_PRODUTOS_V1',

    OTKWEB_INVENTARIO_CADASTROS = 'OTKWEB_INVENTARIO_CADASTROS_V1',
    OTKWEB_INVENTARIO_ITENS = 'OTKWEB_INVENTARIO_ITENS_V1',

    OTKWEB_CNAB_REMESSA_PENDENTE = 'OTKWEB_CNAB_REMESSA_PENDENTE_V1',
    OTKWEB_CNAB_REMESSA_GERADA = 'OTKWEB_CNAB_REMESSA_GERADA_V1',
    OTKWEB_CNAB_RETORNO = 'OTKWEB_CNAB_RETORNO_V1',

    OTKWEB_NOTA_DESTINADAS = 'OTKWEB_NOTA_DESTINADAS_V1',
    OTKWEB_NOTA_DESTINADAS_PRODUTOS = 'OTKWEB_NOTA_DESTINADAS_PRODUTOS_V1',
}

export enum TutorialEnum {
    //EMPRESA

    CLIENTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/491448/clientes-como-cadastrar',
    CLIENTE_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491282/clientes',
    CLIENTE_CATEGORIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/491360/clientes',
    CLIENTE_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491393/clientes-tipos-de-clientes',
    CLIENTE_SITUACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/491398/clientes-situacao-de-clientes',
    CONDICAO_DE_PAGAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516048/condicoes-de-pagamento-como-cadastrar',
    CENTRO_DE_CUSTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183765/centros-de-custo-como-cadastrar',
    CENTRO_DE_CUSTO_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516019/grupos-centros-de-custo-como-cadastrar',
    CLASSIFICACAO_AUXILIAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/516049/classificacoes-auxiliares-como-cadastrar',
    COLABORADOR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772',
    COLABORADOR_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772#h_29480f3b-8d6a-41a3-b627-d1e40fcf6f30',
    ENDERECOS_ADICIONAIS = 'https://ajuda.gestio.com.br/kb/pt-br/article/485895/enderecos-adicionais',
    FILIAL = 'https://ajuda.gestio.com.br/kb/pt-br/article/491335/passo-a-passo-de-como-cadastrar-filial',
    FORNECEDOR = 'https://ajuda.gestio.com.br/kb/pt-br/article/489623/fornecedores-como-cadastrar',
    FORNECEDOR_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489734/fornecedores',
    FORNECEDOR_CONTRATO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489772/fornecedores-como-cadastrar-contratos-de-fornecimento',
    FORNECEDOR_CONTRATO_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/489775/fornecedores-como-cadastrar-tipos-de-contrato-de-fornecimento',
    FORMA_DE_PAGAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516053/formas-de-pagamento-como-cadastrar',
    MAPEAMENTO_DE_RATEIO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516051/mapeamento-de-rateio-como-cadastrar',
    PRODUTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785',
    PRODUTO_GRUPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    PRODUTO_CATEGORIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_95c15283-dd8a-4af7-abf2-c48f52fba471',
    PRODUTO_TIPO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_cf54003b-70e2-4218-b7bd-21d9cf448ebd',
    PROJETO = 'https://ajuda.gestio.com.br/kb/pt-br/article/516012/projetos-como-cadastrar',
    TIPO_DE_DOCUMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bbf1fc85-a7a9-4b32-9b0a-d668c1d5a028',

    //COMPRAS

    COTACOES = 'https://ajuda.gestio.com.br/kb/pt-br/article/183829/cotacao-passo-a-passo-como-comparar?ticketId=&ticketId=&q=&q=&menuId=20335-0-183829',
    ORDEM_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183819/ordens-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20333-0-183819',
    ORDEM_DE_COMPRA_POR_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183824/ordens-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20334-0-183824',
    ORDEM_DE_COMPRA_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/',
    ORDEM_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    PEDIDO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183840/pedido-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20337-0-183840',
    PEDIDO_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    PLANEJAMENTO_ORCAMENTARIO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183857/planejamento-orcamentario-passo-a-passo-como-configurar?ticketId=&q=',
    REQUISICAO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183813/requisicoes-de-compra-passo-a-passo-como-fazer?ticketId=&q=',
    REQUISICAO_DE_COMPRA_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/article/183813/requisicoes-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20332-0-183813#h_0071eafd-2bf8-4122-b822-118bfe16126f',
    REQUISICAO_DE_COMPRA_MINHAS_APROVACOES = 'https://ajuda.gestio.com.br/kb/',
    RECEBIMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183853/recebimento-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20338-0-183853',
    RECEBIMENTO_POR_PEDIDO = 'https://ajuda.gestio.com.br/kb',
    NOTAS_RECEBIDAS = 'https://ajuda.gestio.com.br/kb/',
    NOTAS_RECEBIDAS_SINCRONIZADAS = 'https://ajuda.gestio.com.br/kb/',
    MOTIVOS_DA_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_96ec6e27-cf73-48d6-9784-8e9b039eac53',
    NIVEIS_DE_APROVACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_6731ef77-4a90-4bed-be5d-c6b3a7997ea4',
    REGRAS_DE_APROVACAO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_aeeb3131-6dad-4ea4-89ee-e52c14f2077f',
    TIPO_DE_COMPRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183792/regras-de-compras-passo-a-passo-como-cadastrar?ticketId=&q=#h_8b7905dc-189d-4b2b-a480-250a2db8b48e',
    SUGESTOES_DE_COMPRAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183858/sugestoes-de-compras?ticketId=&ticketId=&q=&q=&menuId=20340-0-183858',
    CONFIGURAÇÕES = 'https://ajuda.gestio.com.br/kb',

    //ESTOQUE

    REQUISICAO_DE_ESTOQUE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183863/requisicao-de-estoque-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20342-0-183863',
    EXPEDICAO_POR_PRODUTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183867/expedicao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20343-0-183867',
    EXPEDICAO_POR_REQUISICAO = 'https://ajuda.gestio.com.br/kb',
    ENTRADAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183872/entrada-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20345-0-183872',
    ENTRADAS_EM_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183881/entrada-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20349-0-183881',
    SAIDAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183873/no-otk-web-voce-consegue-dar-baixa-na-quantidade-de-um-item-de-f?ticketId=&ticketId=&q=&q=&menuId=20346-0-183873',
    TRANSFERENCIAS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183876/transferencia-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20347-0-183876',
    TRANSFERENCIAS_EM_LOTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183884/transferencia-em-lote-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20350-0-183884',
    AJUSTES = 'https://ajuda.gestio.com.br/kb/pt-br/article/183879/ajuste-de-estoque-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20348-0-183879',
    SALDOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183871/saldo-em-estoque-passo-a-passo-como-consultar?ticketId=&ticketId=&q=&q=&menuId=20344-0-183871',
    INVENTARIOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/450004/inventarios-passo-a-passo-como-cria-los?ticketId=&ticketId=&q=&q=&menuId=48751-0-450004',
    ALMOXARIFADOS = 'https://ajuda.gestio.com.br/kb',
    SECOES = 'https://ajuda.gestio.com.br/kb',
    INFORMACOES_COMPLEMENTARES = 'https://ajuda.gestio.com.br/kb',
    ENDERECAMENTOS_DE_PRODUTOS = 'https://ajuda.gestio.com.br/kb',
    MOTIVOS_DE_REQUISICAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    NIVEIS_DE_APROVACAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    REGRAS_DE_APROVACAO_ESTOQUE = 'https://ajuda.gestio.com.br/kb',
    TIPO_DE_REQUISICAO = 'https://ajuda.gestio.com.br/kb',

    //VENDAS

    FATURA = 'https://ajuda.gestio.com.br/kb/article/183923/faturas-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&menuId=20357-0-183923',
    NOTA_FISCAL_PRODUTOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183947/nota-fiscal-de-produto-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20361-0-183947',
    NOTA_FISCAL_SERVICOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183945/nota-fiscal-de-servico-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20360-0-183945',
    ORDEM_DE_SERVICO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183928/ordens-de-servico-passo-a-passo-como-fazer?ticketId=&q=',
    PEDIDO_DE_VENDA = 'https://ajuda.gestio.com.br/kb/pt-br/article/195916/vendas-pedido-de-venda-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=21713-0-195916',
    PREVISAO_FATURA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',
    PREVISAO_SERVICOS = 'https://ajuda.gestio.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',

    //FINANCEIRO

    AGENCIA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_43959041-d1c8-4b4c-9b3e-f009da77386d',
    CATEGORIA = 'https://ajuda.gestio.com.br/kb/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    CARTEIRA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_9ce08242-eeae-46fe-9489-95a1eb3b224f',
    CONTA_CORRENTE = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ba7f7cc2-6739-446d-a746-08f6d76e3def',
    CUSTO_ABC = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_3ec63e27-f23d-4dd6-8125-a5d4cfeecaf0',
    FINANCEIRO_CAIXA = 'https://ajuda.gestio.com.br/kb/pt-br/article/183921/caixa-bancos-passo-a-passo-como-conciliar-saldo?ticketId=&ticketId=&q=&menuId=20355-0-183921',
    FINANCEIRO_CONTAS_A_PAGAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183915/contas-a-pagar-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&q=&menuId=20353-0-183915',
    FINANCEIRO_CONTAS_A_RECEBER = 'https://ajuda.gestio.com.br/kb/pt-br/article/183898/contas-a-receber-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&menuId=20352-0-183898',
    GRUPO_LANCAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bd6fe4a5-683d-4e75-9d15-8142836838d9',
    ITEM_LANCAMENTO = 'https://ajuda.gestio.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    PREVISAO_CONTAS_A_PAGAR = 'https://ajuda.gestio.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
    PREVISAO_CONTAS_A_RECEBER = 'https://ajuda.gestio.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
}
