import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsComponentsGestioModule } from '../buttons/buttons-components.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CmpChartGestioComponent } from './chart/cmp-chart.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, ButtonsComponentsGestioModule],
    exports: [CmpChartGestioComponent],
    declarations: [CmpChartGestioComponent],
})
export class ChartComponentsGestioModule {}
