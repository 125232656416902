import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { routeAnimation } from '../../../../route.animation';
import { StateModeEnum } from 'src/app/app.enums';

@Component({
    selector: 'gestio-cmp-page-area',
    templateUrl: './cmp-page-area.component.html',
    styleUrls: ['./cmp-page-area.component.scss'],
    host: {
        '[@routeAnimation]': 'true',
    },
    animations: [routeAnimation],
})
export class CmpPageAreaGestioComponent implements OnInit, OnDestroy {
    @Input() layoutGap: string = '16px';
    @Input() state$: BehaviorSubject<StateModeEnum>;

    public hasBanner: boolean = false;
    public isEditMode: boolean = false;

    private _stateSubscription$: Subscription;
    private _hasBannerSubscription$: Subscription;

    constructor(private _authService: AuthService) {}

    ngOnInit() {
        this._hasBannerSubscription$ = this._authService.hasBanner$.subscribe(
            (value: boolean) => {
                this.hasBanner = value;
            }
        );

        if (this.state$) {
            this._stateSubscription$ = this.state$.subscribe((r) => {
                this.isEditMode =
                    r == StateModeEnum.insert || r == StateModeEnum.edit;
            });
        }
    }

    ngOnDestroy() {
        if (this.state$) {
            this._stateSubscription$.unsubscribe();
        }
        this._hasBannerSubscription$.unsubscribe();
    }
}
