import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-tabs-dashboard',
    templateUrl: './tabs-dashboard.component.html',
    styleUrls: ['./tabs-dashboard.component.scss'],
})
export class TabsDashboardComponent implements OnInit {
    @Input() tabs: any[] = [];
    @Input() selectedIndex: number = 0;
    @Output() onItemClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        this.tabs = this.tabs.filter((tab) => tab.isVisible);
    }

    actionItemClick(event) {
        this.onItemClick.emit(event);
    }
}
