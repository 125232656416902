import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextAreaComponent } from 'devextreme-angular';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { hasRequiredField } from '../../../../app.functions';
import { AppHelper } from '../../../../app.helper';

@Component({
    selector: 'gestio-textarea-label',
    templateUrl: './textarea-label.component.html',
    styleUrls: ['./textarea-label.component.scss'],
})
export class TextareaLabelGestioComponent implements OnInit {
    @Input() label: string = null;
    @Input() changeLabel: boolean = true;

    @Input() width: string = '100%';
    @Input() height: string = '128px';
    @Input() widthHelp: string = '300px';

    @Input() help: string = null;
    @Input() copyButton: boolean = true;
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';

    @Input() upper: boolean = false;

    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Valor inválido.`;

    @Input() focus$: Subject<boolean> = new Subject<boolean>();

    @Output() onValidMask = new EventEmitter<any>();
    @Output() onClearClick = new EventEmitter<any>();
    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @ViewChild(DxTextAreaComponent) textArea: DxTextAreaComponent;

    popoverVisible: boolean = false;
    upperCaseValue: string = null;

    private focusSubscription: Subscription;

    ngOnInit() {
        this.focusSubscription = this.focus$
            .pipe(filter((b: boolean) => b == true))
            .subscribe(() => {
                setTimeout(() => {
                    if (this.textArea && this.textArea.instance) {
                        this.textArea.instance.focus();
                    }
                }, 0);
            });

        if (this.upper) {
            this.control.valueChanges.subscribe(() => {
                this.upperCaseValue = this.control.value;
                AppHelper.setCtrlValue(
                    this.control,
                    this.control.value.toUpperCase()
                );
            });
        }
    }

    ngOnDestroy() {
        this.focusSubscription.unsubscribe();
    }

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (!this.changeLabel) {
            return this.label;
        }

        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    get validationStatus() {
        return this.control.invalid && this.control.touched
            ? 'invalid'
            : 'valid';
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else {
                if (this.errorInvalid) {
                    return { message: this.errorInvalid };
                } else {
                    return { message: `Inválido.` };
                }
            }
        }
    }

    private _getOnlyValue(v: string) {
        let r = v.trim();
        return r.replace(/\-/g, '');
    }

    actionOnEnterKey(e: any) {
        this.onEnterKeyEvent.emit(this.control.value);
    }

    actionValueChanged(e: any) {
        if (e && e.value && this.textArea) {
            if (this.textArea.isValid) {
                this.onValidMask.next(e.value);
            } else {
                let v = this._getOnlyValue(e.value);
                if (v == null || v.length == 0) {
                    this.textArea.isValid = true;
                } else this.control.setErrors({ invalidMask: true });
            }
        }
    }

    actionContentReady(e: any) {
        const selector = e.element.querySelector('.dx-icon-clear');
        if (selector) {
            selector.addEventListener('click', () => this.onClearClick.emit());
        }
    }
}
